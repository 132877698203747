import React from 'react';
import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import { Value } from 'slate';
import autoBind from 'react-autobind';
import {
	faBold,
	faItalic,
	faUnderline,
	faCode,
	faHeading,
	faQuoteRight,
	faListOl,
	faListUl,
	faLink,
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isMac } from '/client/app/util/userAgentUtils';
import Hideable from '/client/app/components/common/hideable';
import htmlSerializer from './htmlSerializer';

import './slateEditor.scss';
import ArmableButton from '/client/app/components/common/armableButton/armableButton';

const Editor = loadable(() => import('./slateWrapper'));

const DEFAULT_NODE = 'paragraph';

const boldHotkey = 'b';
const italicHotkey = 'i';
const underlinedHotkey = 'u';
const codeHotkey = '`';
const linkHotkey = 'l';

export const defaultValue = Value.fromJSON({
	document: {
		nodes: [
			{
				object: 'block',
				type: 'paragraph',
				nodes: [
					{
						object: 'text',
					},
				],
			},
		],
	},
});

class SlateEditor extends React.Component {
	constructor(props) {
		super(props);
		autoBind(this);

		const { initialValue, editOnMount } = props;

		this.state = {
			value: initialValue || defaultValue,
			editing: editOnMount,
		};
	}

	getMarkURL() {
		const { value } = this.state;
		return value.activeMarks.find((mark) => mark.type === 'anchor')?.data?.get('href');
	}

	hasMark(type) {
		const { value } = this.state;
		return value.activeMarks.some((mark) => mark.type === type);
	}

	hasBlock(type) {
		const { value } = this.state;
		return value.blocks.some((node) => node.type === type);
	}

	ref = (editor) => {
		this.editor = editor;
	};

	getValue() {
		const { value: propValue } = this.props;
		const { value: stateValue } = this.state;
		return propValue ? Value.fromJSON(propValue) : stateValue;
	}

	toggleEdit(reset) {
		const { onEdit, onEditCancel } = this.props;
		let { initialValue } = this.props;
		if (!initialValue) initialValue = defaultValue;
		const { editing } = this.state;
		this.setState((prevState) => ({
			...prevState,
			editing: !prevState.editing,
			value: reset ? initialValue : prevState.value,
		}));
		if (!editing && onEdit) onEdit();
		else if (editing && onEditCancel && reset) {
			onEditCancel();
		}
	}

	getEditView() {
		const { autoFocus, placeholder } = this.props;
		return (
			<>
				<div className="slateToolbar">
					{this.renderMarkButton('bold', <FontAwesomeIcon icon={faBold} />, 'bold')}
					{this.renderMarkButton('italic', <FontAwesomeIcon icon={faItalic} />, 'italic')}
					{this.renderMarkButton('underlined', <FontAwesomeIcon icon={faUnderline} />, 'underline')}
					{this.renderMarkButton('anchor', <FontAwesomeIcon icon={faLink} />, 'anchor')}
					{this.renderMarkButton('code', <FontAwesomeIcon icon={faCode} />, 'code')}
					{this.renderBlockButton('heading-two', <FontAwesomeIcon icon={faHeading} />, 'heading')}
					{this.renderBlockButton('block-quote', <FontAwesomeIcon icon={faQuoteRight} />, 'block-quote')}
					{this.renderBlockButton('numbered-list', <FontAwesomeIcon icon={faListOl} />, 'numbered list')}
					{this.renderBlockButton('bulleted-list', <FontAwesomeIcon icon={faListUl} />, 'bulleted list')}
				</div>
				<Editor
					spellCheck
					autoFocus={autoFocus}
					placeholder={placeholder}
					ref={this.ref}
					value={this.getValue()}
					onChange={this.onChange}
					onKeyDown={SlateEditor.onKeyDown}
					renderBlock={SlateEditor.renderNode}
					renderMark={SlateEditor.renderMark}
					className="commonInput slateInput"
				/>
			</>
		);
	}

	async saveChanges() {
		const { onSave } = this.props;
		const { value } = this.state;
		if (onSave) {
			await onSave(value);
			this.toggleEdit(false);
		}
	}

	getDisplayView(editable) {
		const { onDelete } = this.props;
		const { editing } = this.state;
		return (
			<div className="display">
				<Hideable hidden={!editing}>{this.getEditView()}</Hideable>
				<Hideable hidden={!!editing}>{htmlSerializer.serialize(this.getValue(), { render: false })}</Hideable>
				<Hideable hidden={!editable}>
					<div className="editLinks">
						<Hideable hidden={!onDelete}>
							{() => (
								<ArmableButton key="deleteButton" tiny onConfirm={onDelete}>
									delete
								</ArmableButton>
							)}
						</Hideable>
						<Hideable hidden={!editing}>
							<button type="button" className="textButton editLink" name="save" onClick={this.saveChanges}>
								save
							</button>
						</Hideable>
						<button
							type="button"
							className="textButton editLink"
							name={editing ? 'cancel' : 'edit'}
							onClick={() => this.toggleEdit(editing)}
						>
							{editing ? 'cancel' : 'edit'}
						</button>
					</div>
				</Hideable>
			</div>
		);
	}

	render() {
		const classNames = ['slateEditor'];

		const { className, displayOnly, editable, showEditableBorder, id } = this.props;

		if (className) classNames.push(className);
		if (displayOnly) classNames.push('displayOnly');
		if (editable) classNames.push('editable');
		if (showEditableBorder) classNames.push('withBorder');

		return (
			<div id={id} className={classNames.join(' ')}>
				<Hideable hidden={!!displayOnly}>{() => this.getEditView()}</Hideable>
				<Hideable hidden={!displayOnly}>{() => <>{this.getDisplayView(editable)}</>}</Hideable>
			</div>
		);
	}

	renderMarkButton(type, icon, name) {
		const isActive = this.hasMark(type);

		return (
			<button
				type="button"
				aria-label={name}
				className={`standardButton slateEditorToolButton ${isActive ? 'toolActive' : ''}`}
				onMouseDown={(event) => this.onClickMark(event, type)}
				onClick={(event) => event.preventDefault()}
			>
				{icon}
			</button>
		);
	}

	renderBlockButton(type, icon, name) {
		let isActive = this.hasBlock(type);

		if (['numbered-list', 'bulleted-list'].includes(type)) {
			const {
				value: { document, blocks },
			} = this.state;

			if (blocks.size > 0) {
				const parent = document.getParent(blocks.first().key);
				isActive = this.hasBlock('list-item') && parent && parent.type === type;
			}
		}

		return (
			<button
				type="button"
				aria-label={name}
				className={`standardButton slateEditorToolButton ${isActive ? 'toolActive' : ''}`}
				onMouseDown={(event) => this.onClickBlock(event, type)}
				onClick={(event) => event.preventDefault()}
			>
				{icon}
			</button>
		);
	}

	static renderNode(props, editor, next) {
		const { attributes, children, node } = props;

		switch (node.type) {
			case 'block-quote':
				return <blockquote {...attributes}>{children}</blockquote>;
			case 'bulleted-list':
				return <ul {...attributes}>{children}</ul>;
			case 'heading-one':
				return <h1 {...attributes}>{children}</h1>;
			case 'heading-two':
				return <h2 {...attributes}>{children}</h2>;
			case 'list-item':
				return <li {...attributes}>{children}</li>;
			case 'numbered-list':
				return <ol {...attributes}>{children}</ol>;
			default:
				return next();
		}
	}

	static renderMark(props, editor, next) {
		const { children, mark, attributes } = props;

		switch (mark.type) {
			case 'bold':
				return <strong {...attributes}>{children}</strong>;
			case 'code':
				return <code {...attributes}>{children}</code>;
			case 'italic':
				return <em {...attributes}>{children}</em>;
			case 'underlined':
				return <u {...attributes}>{children}</u>;
			case 'anchor':
				return (
					<a rel="noopener noreferrer" target="_blank" {...attributes}>
						{children}
					</a>
				);
			default:
				return next();
		}
	}

	onChange({ value }) {
		const { onChange } = this.props;
		const { value: stateValue } = this.state;

		if (onChange && value.document !== stateValue.document) onChange(value.toJS(), value);

		this.setState((prevState) => ({ ...prevState, value }));
	}

	static onKeyDown(event, editor, next) {
		let mark;
		if (isMac() ? event.metaKey : event.ctrlKey) {
			switch (event.key) {
				case boldHotkey: {
					mark = 'bold';
					break;
				}
				case italicHotkey: {
					mark = 'italic';
					break;
				}
				case underlinedHotkey: {
					mark = 'underlined';
					break;
				}
				case codeHotkey: {
					mark = 'code';
					break;
				}
				case linkHotkey: {
					mark = 'anchor';
					break;
				}
				default: {
					return next();
				}
			}
		} else return next();

		event.preventDefault();
		editor.toggleMark(mark);
		return null;
	}

	onClickMark(event, type) {
		event.preventDefault();
		if (type === 'anchor') {
			let url = '';
			if (!this.hasMark('anchor')) {
				url = window.prompt('Enter URL', '');
				if (url) {
					if (!/.+:\/\/.+/.test(url)) url = `https://${url}`;
					this.editor.toggleMark({ type, data: { href: url } });
				}
			} else this.editor.toggleMark({ type, data: { href: this.getMarkURL() } });
		} else this.editor.toggleMark(type);
	}

	onClickBlock(event, type) {
		event.preventDefault();

		const { editor } = this;
		const { value } = editor;
		const { document } = value;

		// Handle everything but list buttons.
		if (type !== 'bulleted-list' && type !== 'numbered-list') {
			const isActive = this.hasBlock(type);
			const isList = this.hasBlock('list-item');

			if (isList) {
				editor
					.setBlocks(isActive ? DEFAULT_NODE : type)
					.unwrapBlock('bulleted-list')
					.unwrapBlock('numbered-list');
			} else {
				editor.setBlocks(isActive ? DEFAULT_NODE : type);
			}
		} else {
			// Handle the extra wrapping required for list buttons.
			const isList = this.hasBlock('list-item');
			const isType = value.blocks.some((block) => !!document.getClosest(block.key, (parent) => parent.type === type));

			if (isList && isType) {
				editor.setBlocks(DEFAULT_NODE).unwrapBlock('bulleted-list').unwrapBlock('numbered-list');
			} else if (isList) {
				editor.unwrapBlock(type === 'bulleted-list' ? 'numbered-list' : 'bulleted-list').wrapBlock(type);
			} else {
				editor.setBlocks('list-item').wrapBlock(type);
			}
		}
	}
}
/* eslint-disable react/require-default-props */
SlateEditor.propTypes = {
	onChange: PropTypes.func,
	autoFocus: PropTypes.bool,
	displayOnly: PropTypes.bool,
	editable: PropTypes.bool,
	showEditableBorder: PropTypes.bool,
	onSave: PropTypes.func,
	onEdit: PropTypes.func,
	editOnMount: PropTypes.bool,
	onEditCancel: PropTypes.func,
	onDelete: PropTypes.func,
	// eslint-disable-next-line react/forbid-prop-types
	value: PropTypes.object,
	placeholder: PropTypes.string,
	className: PropTypes.string,
	// eslint-disable-next-line react/forbid-prop-types
	initialValue: PropTypes.object,
	id: PropTypes.string,
};

SlateEditor.defaultProps = {
	showEditableBorder: true,
	editOnMount: false,
	id: undefined,
};

export default SlateEditor;
