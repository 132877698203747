import React from 'react';
import PropTypes from 'prop-types';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './armableButton.scss';
import Hideable from '../hideable';

class ArmableButton extends React.Component {
	isMounted = false;

	constructor(props) {
		super(props);

		this.getArmedControls = this.getArmedControls.bind(this);
		this.disarm = this.disarm.bind(this);
		this.arm = this.arm.bind(this);
		this.ref = React.createRef();
		this.shouldDisarm = this.shouldDisarm.bind(this);
		this.fire = this.fire.bind(this);
		this.state = {
			armed: false,
			firing: false,
			error: false,
			errorMessage: '',
		};
	}

	componentWillUnmount() {
		this.isMounted = false;
	}

	componentDidMount() {
		this.isMounted = true;
	}

	arm() {
		document.addEventListener('mousedown', this.shouldDisarm);
		this.setState(prevState => ({
			...prevState,
			armed: true,
		}));
	}

	disarm() {
		document.removeEventListener('mousedown', this.shouldDisarm);
		this.setState(prevState => ({
			...prevState,
			armed: false,
			error: false,
		}));
	}

	shouldDisarm(event) {
		const { firing } = this.state;

		if (!this.ref.current.contains(event.target) && !firing) this.disarm();
	}

	async fire(e) {
		this.setState(prevState => ({
			...prevState,
			firing: true,
			error: false,
		}));

		const { onConfirm } = this.props;
		if(onConfirm) {
			e.preventDefault();
			try {
				await onConfirm();
				this.disarm();
			} catch (error) {
				this.setState(prevState => ({
					...prevState,
					error: true,
					errorMessage: error.message,
				}));
			}
		}

		// Required isMounted check in case action deletes this button
		if (this.isMounted) {
			this.setState(prevState => ({
				...prevState,
				firing: false,
			}));
		}
	}

	getArmedControls() {
		const { tiny, type } = this.props;
		const { firing, error } = this.state;

		const buttonClass = tiny ? 'tinyButton' : 'standardButton';
		const pulsing = firing ? 'pulsing' : '';
		return (
			<div className="armedButtonControls">
				<Hideable hidden={error}>
					{/* eslint-disable-next-line react/button-has-type */}
					<button type={type} className={`confirmButton ${buttonClass} ${pulsing}`} disabled={firing} onClick={this.fire}>
						<FontAwesomeIcon className="confirmButton" icon={faCheckCircle} aria-hidden="true" />
					</button>
				</Hideable>
				<button type="button" className={`disarmButton ${buttonClass}`} disabled={firing} onClick={this.disarm}>
					<FontAwesomeIcon className="disarmButton" icon={faBan} aria-hidden="true" />
				</button>
			</div>
		);
	}

	render() {
		const { tiny, disabled, children, className } = this.props;
		const { armed, firing, errorMessage, error } = this.state;

		const buttonClasses = ['armableButton'];
		if (tiny) buttonClasses.push('tinyButton');
		else buttonClasses.push('standardButton');
		if (firing) buttonClasses.push('pulsing');
		if (error) buttonClasses.push('error');

		return (
			<div className={`armableButtonContainer ${className}`} ref={this.ref}>
				<button type="button" className={buttonClasses.join(' ')} onClick={this.arm} disabled={disabled || armed}>
					{error ? <span>{errorMessage}</span> : children}
				</button>
				{armed && !disabled ? this.getArmedControls() : ''}
			</div>
		);
	}
}
ArmableButton.propTypes = {
	onConfirm: PropTypes.func,
	disabled: PropTypes.bool,
	tiny: PropTypes.bool,
	className: PropTypes.string,
	children: PropTypes.node,
	type: PropTypes.string
};
ArmableButton.defaultProps = {
	onConfirm: undefined,
	disabled: false,
	tiny: false,
	className: '',
	children: [],
	type: 'button'
};
export default ArmableButton;
