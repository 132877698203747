import React from 'react';
import Html from 'slate-html-serializer';
import { Mark } from 'slate';

const BLOCK_TAGS: { [index: string]: string } = {
	p: 'paragraph',
	li: 'list-item',
	ul: 'bulleted-list',
	ol: 'numbered-list',
	blockquote: 'block-quote',
	pre: 'code',
	h1: 'heading-one',
	h2: 'heading-two',
};

const MARK_TAGS: { [index: string]: string } = {
	strong: 'bold',
	em: 'italic',
	u: 'underlined',
	code: 'code',
	a: 'anchor'
};

const RULES = [
	{
		deserialize(el: Element, next: Function) {
			const block: string = BLOCK_TAGS[el.tagName.toLowerCase()];
			if (block) {
				return {
					object: 'block',
					type: block,
					nodes: next(el.childNodes),
				};
			}

			return undefined;
		},
		serialize(obj: any, children: any) {
			if (obj.object === 'block') {
				switch (obj.type) {
					case 'paragraph':
						return <p>{children}</p>;
					case 'list-item':
						return <li>{children}</li>;
					case 'bulleted-list':
						return <ul>{children}</ul>;
					case 'numbered-list':
						return <ol>{children}</ol>;
					case 'block-quote':
						return <blockquote>{children}</blockquote>;
					case 'code':
						return <code>{children}</code>;
					case 'heading-one':
						return <h1>{children}</h1>;
					case 'heading-two':
						return <h2>{children}</h2>;
					default:
						return undefined;
				}
			}
			return undefined;
		},
	},
	{
		deserialize(el: Element, next: Function) {
			const mark: string = MARK_TAGS[el.tagName.toLowerCase()];

			if (mark) {
				return {
					object: 'mark',
					type: mark,
					nodes: next(el.childNodes),
					data: {href: el.getAttribute('href')}
				};
			}

			return undefined;
		},
		serialize(obj: any, children: any) {
			if (obj.object === 'mark') {
				switch (obj.type) {
					case 'bold':
						return <strong>{children}</strong>;
					case 'italic':
						return <em>{children}</em>;
					case 'underlined':
						return <u>{children}</u>;
					case 'code':
						return <code>{children}</code>;
					case 'anchor':
						return <a rel="noopener noreferrer" target="_blank" href={(obj as Mark).data.get("href")}>{children}</a>
					default:
						return undefined;
				}
			}
			return undefined;
		},
	},
];

declare const parseHTMLFragment: any;

const parseHtml =
	typeof parseHTMLFragment === 'function' && parseHTMLFragment
		? parseHTMLFragment
		: (html: any) => new DOMParser().parseFromString(html, 'text/html');

const serializer = new Html({ rules: RULES, parseHtml });

export default serializer;
